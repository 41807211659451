$default_black: #000000;
$default_white: #ffffff;
$default_main: #272b35;

$default_black_transparent: rgba(32, 32, 32, 0.466);

$default_grey: #cccccc;
$default_grey_darker: rgba(0, 40, 100, 0.12);
$default_red: #dd1515;

$default_shadowColor: rgba(210, 198, 225, 0.28);
$default_calendarItem: #3174ad;

$default_disabledGrey: #f8f9fa;
$default_alto: #e0e0e0;
$default_matisse: #216ba5;
$default_wild_sand: #f5f5f5;
$default_alto: #dddddd;
$default_havelockBlue: #467fcf;
$default_forestGreen: #1e7e34;
$default_stTropaz: #2c689c;
$default_trout: #495057;
$default_cream: #ffffd1;
$default_catskill_white: #fbfcfd;
$default_silver: #b9b9b9;
$default_concrete: #f3f3f3;
$default_thunderbird: #cd201f;
$default_silver_second: #cacaca;
$default_geyser: #cfd6e0;
$default_black_second: #0000000f;
$default_silver_chalice: #9e9e9e;
$default_portafino: #fdffb1;
$default_mercury: #e2e2e2;
$default_boulder: #777777;
$default_nobel: #b5b5b5;
$default_seashell: #f1f1f1;
$default_jungle_mist: #bdccd9;
$default_gallery: #ececec;
$default_koromiko: #ffbf66;
$default_ming: #33657e;
$default_sunglo: #e56666;
$default_regent_gray: #93a2a8;
$default_pastel_green: #5be298;
$default_texas_rose: #ffb45a;
$default_orange: #ff6641;
$default_pastel_grey: #b9b7b7;
$default_silver_chalice: #aba8a8;
$default_dove_gray: #707070;
$default_charade: #272b35;
// NEW LEYAUT

$default_mirage: #1a1e27;
$default_outrageous_orange: #ff6641;
$date_picker_bg_color: #14171e;
$date_picker_bg_image: none;
$date_picker_color_selected: $default_white;
$default_catskill_white: #fbfcfd;
$default_thunderbird: #cd201f;
$default_silver: #b9b9b9;
$default_concrete: #f3f3f3;
$default_portafino: #fdffb1;
$default_mercury: #e2e2e2;
$default_bunker: #14171e;
$default_crusta: #fa803f;
$default_shark: #1a1c24;
$default_charade: #272b35;
$default_halfBlack: rgba(255, 255, 255, 0.051);
$default_trout: #4d4f5c;
$default_mercury: #e1e1e1;
$default_tuna: #30343d;
$default_mako: #454951;
$default_outrageous_orange_opacity: rgba(255, 102, 65, 0.3);
