@import '@Styles/defs/colors.module.scss';
@import '@Styles/defs/props.module.scss';

:global(.smallMobile),
:global(.mobile),
:global(.tablet) {
  :global {
    .react-datepicker {
      padding-top: 1.875rem;

      .react-datepicker__navigation {
        top: 2.5rem;
      }
    }
  }
}

:global {
  .react-datepicker {
    background-color: $default_black;
    border: none;
    border-radius: 1.25rem;
    color: $default_white;
    font: $default_font;
    font-size: 0.625rem;
    width: 100%;

    .react-datepicker__navigation {
      border: 0.625rem solid transparent;
      top: 1.25rem;
    }

    .react-datepicker__navigation--previous {
      background-image: url('~@Styles/images/arrow-left.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      border-right-color: $default_white;
      height: 1.25rem;
      left: auto;
      right: calc(100% - 10%);
      width: 0.6875rem;
    }

    .react-datepicker__navigation--next {
      background-image: url('~@Styles/images/arrow.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border: none;
      border-left-color: $default_white;
      border-right-color: $default_white;
      height: 1.25rem;
      left: calc(100% - 10%);
      width: 0.6875rem;
    }

    .react-datepicker__month-container {
      font-family: $default_font;
      width: 100%;
    }

    .react-datepicker__header {
      background-color: $default_black;
      background-color: transparent;
      border: none;
      color: $default_orange;
      padding: 0;
      width: 100%;
    }

    .react-datepicker__month {
      border-collapse: collapse;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0 5% 5% 5%;
      width: 100%;
    }

    .react-datepicker__current-month {
      color: $default_orange;
      font-family: $default_font;
      font-size: 1.125rem;
      font-weight: 400;
      margin-top: 1.25rem;
      text-transform: capitalize;
    }

    .react-datepicker__week {
      display: flex;

      &:first-child {
        border-top: none;
      }

      .react-datepicker__day--disabled {
        color: #494b50;
        flex: 1;
        font-weight: 400;
        transition: $default_transitionTime;

        &:hover {
          background-color: transparent;
          color: $default_white;
        }

        &.react-datepicker__day--selected {
          background-color: rgba(14, 6, 107, 0.3);
          color: $default_white;
        }
      }
    }

    .react-datepicker__day-names {
      display: flex;
      justify-content: space-around;
      padding: 0 5%;
      text-transform: uppercase;

      .react-datepicker__day-name {
        border-radius: 0;
        color: $default_white;

        flex: 1;
        font-family: $default_font;
        font-size: 0.625rem;
        font-weight: 500;
        margin: 0;
        padding: 1rem 0;
        padding-top: 2.25rem;

        &:last-child {
          border-right: none;
        }
      }
    }

    .react-datepicker__day {
      align-items: center;
      background-color: transparent;
      border: 0.0625rem solid transparent;
      border-bottom: none;
      border-radius: 3.125rem;
      border-right: none;
      color: $default_white;
      display: table-cell;

      display: flex;
      flex: 1;
      font-weight: 400;
      justify-content: center;
      margin: 0.1875rem;
      transition: $default_transitionTime;

      width: 100%;

      &:hover {
        background-color: transparent;
        background-position: center;
        background-size: auto;
        border-color: $default_orange;

        -webkit-box-shadow: 0rem 0rem 1.1875rem -0.1875rem rgba(255, 102, 65, 0.7);
        box-shadow: 0rem 0rem 1.1875rem -0.1875rem rgba(255, 102, 65, 0.7);
        color: $default_white;
      }

      &:before {
        content: '';
        float: left;
        padding-top: 100%;
      }
    }

    .react-datepicker__day--selected {
      background: linear-gradient(
        180deg,
        $default_crusta 0%,
        $default_orange 100%
      );
      background-position: center;
      background-size: auto;
      border-radius: inherit;
      border-radius: 3.125rem;
      color: $date_picker_color_selected;
      transition: $default_transitionTime;

      &:hover {
        background-color: $default_orange;
      }
    }

    .react-datepicker__day--outside-month {
      color: $default_grey;
      flex: 1;
    }
  }
}
