@import '@Misc/styles/defs/colors.module.scss';

// Default
$default_desktopWidth: 60rem;
$default_font: 1rem sans-serif;
$default_shadow: 0.039063rem 0.078125rem 1.25rem $default_shadowColor;

// Modal
$modal_desktopMinWidth: 11.875rem;
$modal_desktopMaxWidth: 90vw;

// Default
$default_font: 'NoirPro', sans-serif;
$default_font_bold: 'NoirPro-Bold', sans-serif;

//New layout
$default_dropShadow: drop-shadow(0 1.25rem 3.75rem rgba(255, 255, 255, 0.051));

$default_animationTime: 400ms;

$default_transitionTime: 300ms;
