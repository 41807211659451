@import '@Misc/styles/defs/colors.module.scss';

.form {
  width: calc(100vw-1.25rem);
}

.card {
  height: auto;
  overflow-y: auto;
}

.loaderWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.wrapper {
  color: $default_white;
  padding: 0.625rem;
}

.checker {
  display: flex;
  height: 1.875rem;
  justify-content: center;
  padding-left: 0.117188rem;
}

.item {
  border-bottom: 0.0625rem solid $default_pastel_grey;
  display: flex;
  font-size: 1.25rem;
  justify-content: space-between;
}
.listItem {
  border-bottom: 0.0625rem solid $default_pastel_grey;

  .productsList {
    padding: 0 0.625rem;

    .product {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 0.625rem 0.625rem 0.625rem;
    }
  }
}

.bigItem {
  border-bottom: 0.0625rem solid $default_pastel_grey;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calendar {
  align-self: center;
  min-width: 18.75rem;
  padding: 0.625rem;
}

:global(.smallMobile),
:global(.mobile) {
  .bigItem {
    flex-direction: column;
  }
}

.formGroup {
  &:first-child {
    margin: 0;
    padding: 0;
  }
}

.title {
  font-size: 1.25rem;
  padding: 0.625rem;
}

.description {
  padding: 0.625rem;
}

.change {
  cursor: pointer;
}
