@import '@Misc/styles/defs/colors.module.scss';
@import '@Misc/styles/defs/props.module.scss';

.wrapper {
  margin-bottom: 1.25rem;
  position: relative;
  width: 100%;

  .iconWrapper {
    border-radius: 0.625rem;
    display: flex;
    height: 3.4375rem;
    position: relative;
    transition: $default_transitionTime;
    z-index: 999;

    &:hover {
      background-color: $default_outrageous_orange_opacity;

      .icon {
        background-color: $default_white;
      }
    }

    &:global(.active) {
      background-color: $default_outrageous_orange;

      .icon {
        background-color: $default_white;
      }
    }

    .link {
      color: $default_outrageous_orange;
    }
  }
}

.icon {
  background-color: $default_pastel_grey;
  height: 1.5625rem;
  margin-right: 0.5rem;
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  width: 1.5625rem;

  &:global(.calendar) {
    mask-image: url('~@Images/menu/calendar.svg');
  }
  &:global(.people) {
    mask-image: url('~@Images/menu/people.svg');
  }
  &:global(.link) {
    mask-image: url('~@Images/menu/link.svg');
  }
  &:global(.add) {
    mask-image: url('~@Images/menu/add.svg');
  }
  &:global(.grid) {
    mask-image: url('~@Images/menu/grid.svg');
  }
  &:global(.tickets) {
    mask-image: url('~@Images/menu/tickets.svg');
  }
  &:global(.check) {
    mask-image: url('~@Images/menu/check.svg');
  }
  &:global(.statistic) {
    mask-image: url('~@Images/menu/statistic.svg');
  }
  &:global(.key) {
    mask-image: url('~@Images/menu/key.svg');
  }
  &:global(.reports) {
    mask-image: url('~@Images/menu/reports.svg');
  }
  &:global(.printers) {
    mask-image: url('~@Images/menu/printers.svg');
  }
  &:global(.discount) {
    mask-image: url('~@Images/menu/discount_codes.svg');
  }
  &:global(.qrCode) {
    mask-image: url('~@Images/menu/qr_code.svg');
  }
  &:global(.delayedTransactions) {
    mask-image: url('~@Images/menu/delayed_transactions.svg');
  }
  &:global(.events) {
    mask-image: url('~@Images/menu/events.svg');
  }
  &:global(.rules) {
    mask-image: url('~@Images/menu/rules.svg');
  }
  &:global(.ticketTypes) {
    mask-image: url('~@Images/menu/tickets_types.svg');
  }
  &:global(.products) {
    mask-image: url('~@Images/menu/products.svg');
  }
  &:global(.settlements) {
    mask-image: url('~@Images/menu/settlements.svg');
  }
  &:global(.mailing) {
    mask-image: url('~@Images/menu/mailing.svg');
  }
  &:global(.accountSettings) {
    mask-image: url('~@Images/menu/account_settings.svg');
  }
  &:global(.consentsList) {
    mask-image: url('~@Images/menu/consents_list.svg');
  }
  &:global(.reportBug) {
    mask-image: url('~@Images/menu/report_a_bug.svg');
  }
  &:global(.poolsDescriptions) {
    mask-image: url('~@Images/menu/pools_descriptions.svg');
  }
  &:global(.scanTicket) {
    mask-image: url('~@Images/menu/scan_ticket.svg');
  }
  &:global(.refundsRegistration) {
    mask-image: url('~@Images/menu/refunds_registration.svg');
  }
  &:global(.transactions) {
    mask-image: url('~@Images/menu/transactions.svg');
  }
}

:global(.active) {
  li {
    background-color: $default_outrageous_orange;
  }

  .icon {
    background-color: $default_white;
  }
}

.name {
  color: $default_white;
  text-decoration: none;

  &:hover {
    color: $default_white;
    text-decoration: none;
  }
}
