@import '@Misc/styles/defs/colors.module.scss';
@import '@Misc/styles/defs/props.module.scss';

.wrapper {
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
  width: 100%;
}

.timeErrorMessage {
  color: red;
  text-align: center;
}

.duration {
  background: $default_mirage;
  border: 0.0625rem solid $default_geyser;
  border-radius: 0.125rem;
  display: flex;
  height: 1.875rem;
  position: relative;

  .marker {
    background: $default_outrageous_orange;
    border-radius: 0.625rem;
    bottom: 0.6875rem;
    left: 33.3333%;
    position: absolute;
    top: 0.6875rem;
    width: 30%;
  }

  .hour {
    position: relative;

    &:after {
      background: $default_black_second;
      content: '';
      display: block;
      height: 100%;
      left: 50%;
      position: absolute;
      top: 0;
      width: 0.0625rem;
    }

    &:before {
      bottom: -1.25rem;
      color: $default_silver_chalice;
      content: attr(data-title);
      font-size: 0.421875rem;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    }
  }

  .even::before {
    bottom: unset;
    top: -1.25rem;
  }

  .point {
    appearance: none;
    background: $default_white;
    border-radius: 0.25rem 0.25rem 100% 100%;
    box-shadow: 0 0.125rem 0.625rem $default_black_second;
    cursor: pointer;
    height: 1.375rem;
    left: 33.3333%;
    position: absolute;
    top: 0.4375rem;
    transform: translate(-50%, -50%);
    transition: $default_transitionTime;
    user-select: none;
    width: 0.75rem;

    &:hover {
      background: $default_portafino;
    }
  }
}

.info {
  display: flex;
  justify-content: space-between;

  h4 {
    color: $default_silver;
    font-size: 0.46875rem;
    margin: 0;
    padding: 0;
  }

  & > div:last-child {
    text-align: right;
  }
}

.days {
  color: $default_white;
  font-size: 0.546875rem;
  margin: 0 0.625rem;
}

.button {
  background: $default_mercury;
  border: none;
  border-radius: 0.117188rem;
  color: $default_boulder;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  width: 1.875rem;
}

.formGroup {
  &:first-child {
    margin: auto;
    margin-top: 0.625rem;
    width: auto;
    width: 4.25rem;
  }
  &:nth-of-type(2) {
    top: 2.5rem !important;
  }
}

:global(.timePicker) {
  margin-bottom: 1.25rem;
  top: 2.5rem;
}
