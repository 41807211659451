@import '@Misc/styles/defs/colors.module.scss';

.datePicker {
  display: flex;
  justify-content: center;

  &.disabled {
    pointer-events: none;

    & > div * {
      background: $default_disabledGrey;
      color: $default_grey;
    }
  }
}
