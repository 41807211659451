@import '@Misc/styles/defs/mixins.module.scss';

.wrapper {
  background: $default_black_transparent;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 99999;

  .title {
    color: $default_white;
    display: inline-block;
    left: calc(50% - 6.25rem);
    position: absolute;
    top: calc(50% + 3.125rem);
  }
}
