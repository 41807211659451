@import '@Styles/defs/colors.module.scss';
@import '@Styles/defs/props.module.scss';

.container {
  .timeSlot {
    align-items: center;
    background-color: $default_black;
    border: .3125rem solid $default_black;
    border-radius: .625rem;
    color: $default_white;
    cursor: pointer;
    display: flex;
    font-size: 1rem;
    font-weight: 400;
    height: 3.5rem;
    justify-content: center;
    min-height: 2.5rem;
    padding: .375rem 1rem;
    position: relative;
    text-align: center;
    user-select: none;

    &.disable {
      opacity: 0.3;
    }

    &.enable {
      opacity: 1;
    }

    &.selected,
    &:hover {
      border: .3125rem solid $default_orange;
      color: $default_orange;
      opacity: 1;
    }

    .upSellBox {
      align-items: center;
      background-image: url('~@Images/bundle_small.svg');
      color: $default_black;
      display: flex;
      font-size: .75rem;
      height: 2.625rem;
      justify-content: center;
      opacity: 1;
      padding-bottom: .3125rem;
      position: absolute;
      right: -0.3125rem;
      top: -1.5625rem;
      width: 2.625rem;
    }
  }
}
