@import '@Misc/styles/defs/colors.module.scss';

.picker {
  background: $default_white;
  border: 0.0625rem solid $default_grey;
  border-radius: 0.125rem;
  display: inline-flex;
  left: 0;
  position: absolute;
  text-align: right;
  top: 2.5rem;
  z-index: 1;
}
