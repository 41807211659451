@import '@Misc/styles/defs/colors.module.scss';
@import '@Misc/styles/defs/mixins.module.scss';
@import '@Misc/styles/defs/props.module.scss';

.header {
  .title {
    color: $default_white;
    font-size: 1.5rem;
    font-weight: 400;
  }
  .description {
    border-bottom: 0.03125rem solid $default_silver_chalice;
    color: $default_pastel_grey;
    font-size: 1.125rem;
    font-weight: 300;

    padding-bottom: 2rem;
  }
}

.childrenWrapper {
  margin-bottom: 2.125rem;
}

.hide {
  display: none !important;
}

.disabled {
  opacity: 0.3;
}
