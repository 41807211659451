@import '@Misc/styles/defs/colors.module.scss';
@import '@Styles/defs/props.module.scss';

@keyframes startingAnimation {
  0% {
    opacity: 0;
    transform: translateY(6.25rem);
  }

  100% {
    opacity: 1;
  }
}

.toast {
  animation: startingAnimation 300ms 1;
  float: right;
  margin-top: 0.625rem;
  opacity: 1;
  transition: all 600ms;
  width: 100%;

  &.hidden {
    opacity: 0;
    transform: translateY(6.25rem);
  }
}

.inner {
  align-items: center;
  border-radius: 0.3125rem;
  box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.2);
  color: $default_white;
  display: flex;
  font-size: 1rem;
  justify-content: center;
  min-height: 2.5rem;
  padding: 0.46875rem 1.25rem;
  text-align: center;
  transition: all 600ms;
  width: 100%;
}

.button {
  background: none;
  border: none;
  color: $default_white;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  margin-left: 0.3125rem;
  opacity: 0.5;
  padding: 0;
  transition: $default_transitionTime;

  &:hover {
    opacity: 1;
  }
}

.added {
  background: $default_koromiko;
}

NaNpxoved {
  background: $default_ming;
}

.error {
  background: $default_sunglo;
}

.info {
  background: $default_regent_gray;
}

.success {
  background: $default_pastel_green;
}

.warning {
  background: $default_texas_rose;
}
