.list {
  color: white;
  margin-top: 1.875rem;
}

.loader {
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;
}

.addButton {
  align-items: center;
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
}
