@import '@Misc/styles/defs/colors.module.scss';
@import '@Misc/styles/defs/mixins.module.scss';

.tile {
  align-items: center;
  background-color: rgb(78, 186, 110);
  border-radius: 0.625rem;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  height: 100%;
  justify-content: center;
  min-height: 3.75rem;
  overflow: hidden;
  padding: 1rem;
  position: relative;
  text-align: center;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;

  span {
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.transparent {
    opacity: 0.3;
  }
  &.selected {
    border: 0.3125rem solid $default_white;
    padding: 0.6875rem;
  }
}

.productsCategory {
  color: $default_pastel_grey;
  font-size: 1.125rem;
  font-weight: 300;
  margin-bottom: 1.5rem;
}
.steeperGrid {
  position: relative;

  .bundle {
    background-image: url('~@Images/buddleFull.png');
    background-size: 100% 100%;
    display: none;
    font-size: 0.75rem;
    font-weight: 400;
    height: 2.8125rem;
    left: -50%;
    line-height: 0.9rem;
    position: relative;
    position: absolute;
    top: -2.8125rem;
    width: 16.0625rem;

    &.selected {
      align-items: center;
      display: block;
      display: flex;
      justify-content: center;
    }

    .text {
      margin-top: -0.5625rem;
    }
  }

  .productCount {
    align-items: center;
    background-color: $default_main;
    border: 0.0625rem solid $default_white;
    border-radius: 0.625rem;
    display: flex;
    font-size: 1.5rem;
    left: 50%;
    padding: 1rem;
    position: absolute;
    transform: translate(-50%, 0.5rem);
    z-index: 999;

    .count {
      text-align: center;
      width: 1.5rem;
    }

    .button {
      background-color: $default_black;
      border-radius: 0.625rem;
      cursor: pointer;
      font-size: 3rem;
      line-height: 0.5;
      margin: 1rem;
      padding: 1rem;
      text-align: center;
      transition: 0.1s;
      user-select: none;
      width: 3.4375rem;

      &:hover {
        background: linear-gradient($default_black, $default_black) padding-box,
          linear-gradient(
              240.73deg,
              $default_crusta 38.28%,
              $default_orange 97.65%
            )
            border-box;
        border: 0.3125rem solid transparent;
        border-radius: 0.625rem;
        padding: 0.6875rem;
      }
    }
  }
}
