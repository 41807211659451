@import '@Styles/defs/colors.module.scss';

.wrapper {
  align-items: center;
  background: $default_white;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.unauthorized {
  padding: 1.875rem;
  text-align: center;
}
