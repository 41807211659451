@import '@Misc/styles/defs/colors.module.scss';
@import '@Misc/styles/defs/mixins.module.scss';

.filterTitle {
  font-size: 0.9375rem;
  margin: 0;
}

.color {
  @include color;
}

.formGroup {
  height: 100%;
  margin-right: 1.125rem;
  max-width: 17.5rem;
  width: 100%;

  & > div {
    height: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-around;
}
