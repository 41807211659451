@import '@Misc/styles/defs/colors.module.scss';
@import '@Misc/styles/defs/mixins.module.scss';

:global(.smallMobile) {
  .summaryBox {
    .basketWrapper {
      .product {
        .row {
          flex-direction: column;
          position: relative;

          .third {
            justify-content: flex-start;
          }

          .forth {
            position: absolute;
            right: 0;
            top: 0.3125rem;
          }
        }
      }
    }
  }
}

.summaryBox {
  .mainBasketTitle {
    font-size: 1.25rem;
  }

  .info {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 300;
    line-height: 1.5rem;

    .emptyBasket {
      background-image: url('~@Images/emptyBasket.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 16.25rem;
      width: 100%;
    }
  }

  .basketWrapper {
    .title {
      font-size: 1.25rem;
      font-style: normal;
      font-weight: normal;
      margin-bottom: 2rem;
    }
    .product {
      margin-bottom: 1.0625rem;
      .row {
        display: flex;
        justify-content: space-between;

        .first {
          align-items: center;
          align-items: flex-start;
          display: flex;

          flex: 2;
          flex-direction: column;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 300;
          justify-content: center;

          &.upSell {
            color: $default_orange;
          }

          .description {
            color: $default_pastel_grey;
            font-size: 1rem;
            font-style: normal;
            font-weight: 300;
            line-height: 1.1875rem;
          }
        }
        .second {
          align-items: center;
          align-items: flex-start;
          display: flex;

          flex: 2;
          flex-direction: column;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 300;
          justify-content: center;

          .description {
            color: $default_pastel_grey;
            font-size: 1rem;
            font-style: normal;
            font-weight: 300;
            line-height: 1.1875rem;
          }
        }
        .third {
          align-items: start;
          display: flex;
          flex: 1;
          font-size: 1.5rem;
          font-style: normal;
          font-weight: normal;
          justify-content: center;
          line-height: 1.8125rem;
        }
        .forth {
          align-items: start;
          display: flex;
          justify-content: center;

          .closeIcon {
            color: $default_dove_gray;
            cursor: pointer;
            font-size: 1.75rem;
          }
        }
      }
      .secondRow {
        color: $default_pastel_grey;
        display: flex;
        font-size: 1rem;
        font-style: normal;
        font-weight: 300;
        justify-content: space-between;
        line-height: 1.1875rem;
      }
    }
  }
  .summary {
    align-items: center;
    border-top: 0.0625rem solid $default_dove_gray;
    color: $default_orange;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
    padding-top: 2.5625rem;

    .left {
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 300;
      line-height: 1.5rem;
    }
    .right {
      font-size: 1.75rem;
      font-style: normal;
      font-weight: normal;
      line-height: 2.375rem;
      text-align: right;
    }
  }

  .iconWithButton {
    align-items: center;
    border: 0.0625rem solid $default_pastel_grey;
    border-radius: 0.625rem;
    display: flex;
    filter: drop-shadow(0rem 0.1875rem 1.875rem rgba(0, 0, 0, 0.149));
    height: 3.5rem;
    width: 100%;

    &.withMargin {
      margin-top: 0.625rem;
    }

    .customInput {
      background: none;
      border: none;
      border-right: 0.0625rem solid $default_pastel_grey;
      color: $default_pastel_grey;
      flex: 1;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 300;
      height: 100%;
      line-height: 1.375rem;
      overflow: auto;
      padding-left: 1rem;

      &.withoutButton {
        border: none;
        padding-right: 1rem;
      }

      &:focus {
        background: none;
        outline: none;
      }
    }
    .customButton {
      align-items: center;
      color: $default_white;
      display: flex;
      flex: 0 0 4.375rem;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: normal;
      height: 100%;
      justify-content: center;
      line-height: 1.8125rem;
    }
  }
}

.transactionTypeWrapper {
  .title {
    font-size: 1.25rem;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5rem;
    margin-bottom: 1.6875rem;
    margin-top: 2.375rem;
  }
}

.saveButton {
  align-items: center;
  background: linear-gradient(240.73deg, #fa803f 38.28%, #ff6641 97.65%);
  border-radius: 1.5rem;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  justify-content: center;
  line-height: 1.1875rem;
  margin-bottom: 0.9375rem;
  margin-top: 1.75rem;
  min-height: 2.5rem;
  padding: 0.6875rem;
  position: relative;
  text-align: center;
}

.shareBasketButton {
  align-items: center;
  border: 0.0625rem solid $default_white;
  border-radius: 1.5rem;
  cursor: pointer;
  display: flex;
  font-size: 1rem;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  justify-content: center;
  line-height: 1.1875rem;
  margin-bottom: 0.9375rem;
  margin-top: 1.75rem;
  min-height: 2.5rem;
  padding: 0.6875rem;
  text-align: center;
}

.inputsContainer {
  @include disabledClass(0.3);
}

.notification {
  left: 50%;
  top: 3.125rem;
  transform: translate(-50%, 0);

  &::after {
    left: 50%;
    top: -0.625rem;
    transform: rotate(180deg);
  }
}
