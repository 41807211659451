@import '@Misc/styles/defs/colors.module.scss';
@import '@Misc/styles/defs/mixins.module.scss';

.closeIcon {
  color: $default_dove_gray;
  cursor: pointer;
  font-size: 1.75rem;
}

.steeperGrid {
  color: $default_white;
  font-size: 1.125rem;
  padding-bottom: 0.25rem !important;
}
.ticketTypeGrid {
  margin-top: 2rem !important;

  .steeperTicketTitle {
    color: $default_pastel_grey;
    font-size: 1.125rem;
  }
}

.ticketSelectGrid {
  margin-top: 1.125rem !important;

  .steeperTicketTitle {
    color: $default_pastel_grey;
    font-size: 1.125rem;
  }
  .steeperTicketPrice {
    align-items: center;
    color: $default_pastel_grey;
    color: $default_outrageous_orange;
    display: flex;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: normal;
    line-height: 2rem;
  }
}

.steeperGridDescription {
  color: $default_pastel_grey;
  font-size: 1rem;
  padding-top: 0 !important;
}
