@import '@Misc/styles/defs/mixins.module.scss';
@import '@Misc/styles/defs/colors.module.scss';
@import '@Styles/defs/props.module.scss';

.wrapper {
  background-color: $default_charade;
  color: $default_white;
  min-height: 37.5rem;
  height: 37.5rem;
}

.smallColumn {
  flex-grow: unset !important;
}

.clearFilterButton {
  background-color: transparent !important;
  border-color: $default_white !important;
  margin-bottom: 1.25rem;
  margin-right: 1.25rem;
  transition: $default_transitionTime;

  &:hover {
    background-color: $default_outrageous_orange !important;
  }

  &:global(.btn-primary) {
    background-color: transparent !important;
    border-color: $default_white !important;
    transition: $default_transitionTime;

    &:hover {
      background-color: $default_outrageous_orange !important;
    }
  }
}

.loader {
  @include loader();
}

.hideToday {
  :global(.rbc-calendar) {
    :global(.rbc-toolbar) {
      :global(.rbc-btn-group) {
        &:first-child {
          button {
            &:first-of-type {
              display: none;
            }
          }
        }

        &:last-child {
          button {
            background-color: $default_outrageous_orange;
          }
        }
      }
    }
  }
}

:global(.rbc-calendar) {
  :global(.rbc-toolbar) {
    :global(.rbc-btn-group) {
      button {
        transition: $default_transitionTime;

        &:hover {
          background-color: $default_outrageous_orange;
        }
      }
      &:last-child {
        button {
          background-color: $default_outrageous_orange;
        }
      }
    }
  }
  :global(.rbc-allday-cell) {
    display: none;
  }
  :global(.rbc-header) {
    border-bottom: none;
  }
}

.filters {
  background-color: $default_outrageous_orange !important;
  border-color: $default_outrageous_orange !important;
  &:focus {
    box-shadow: none !important;
  }
}
