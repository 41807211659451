@import 'react-datepicker/dist/react-datepicker-cssmodules.css';

@import '@Styles/defs/props.module.scss';
@import '@Styles/fonts.module.scss';
@import '@Misc/styles/defs/colors.module.scss';
@import '@Styles/datepicker.module.scss';

body {
  background: linear-gradient(269.92deg, #4f525c 0.07%, #12141d 99.92%);
  color: $default_white;
  font-size: 1rem; // px fallback
  height: auto !important;
  line-height: 1.5; // a nice line-height
  position: absolute;
}

// Other resets
body,
button,
input,
textarea {
  font: $default_font;
}

body {
  font-family: $default_font !important;
  overflow-y: scroll;
}

body,
h1,
h2,
h3,
h4,
h5,
p,
li,
ul {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

body {
  font-family: $default_font_bold;
}

:global(.header) {
  background: whitesmoke !important;
  border: none !important;
}

h3 {
  color: white !important; // TODO: remove as all vies will be done
}

:global(.card) {
  background-color: transparent !important; // TODO: remove as all vies will be done
  box-shadow: none !important; // TODO: remove as all vies will be done
}

:global(.rbc-calendar) {
  :global(.rbc-today) {
    background-color: rgba(255, 255, 255, 0.1);
  }
  :global(.rbc-toolbar) {
    :global(.rbc-btn-group) {
      button {
        color: $default_white;
      }
    }
  }
}

:global(.form-control) {
  background-color: $default_charade !important;
  border: 0.0625rem solid $default_pastel_grey !important;
  border-radius: 0.625rem !important;
  color: $default_white !important;
  font-size: 1.125rem !important;
}

:global(.card) {
  background-color: $default_charade !important;
}

:global(.list-group-item) {
  background-color: $default_pastel_grey !important;
}
:global(.form-label) {
  color: $default_white !important;
}
:global(.form-group) {
  color: $default_white !important;
}

:global(.btn-primary) {
  background-color: $default_outrageous_orange !important;
  border-color: $default_outrageous_orange !important;
}

:global(.table) {
  font-size: 1rem;
  thead {
    th {
      font-size: 1rem;
    }
  }
}

:global(.custom-select) {
  height: auto !important;
}

:global(.rbc-time-content) {
  & > * + * {
    & > * {
      border-color: $default_pastel_grey !important;
    }
  }
}

:global(.rbc-day-slot) {
  :global(.rbc-time-slot) {
    border: none !important;
  }
  :global(.rbc-event) {
    font-size: 0.9375rem;
  }
  :global(.rbc-event-label) {
    font-size: 0.9375rem;
  }
}

:global(.rbc-timeslot-group) {
  border-color: $default_pastel_grey !important;
}

:global(.rbc-time-content) {
  border-color: $default_pastel_grey !important;
}

:global(.rbc-time-view) {
  border-color: $default_pastel_grey !important;
}

:global(.rbc-header) {
  border-color: $default_pastel_grey !important;
}

:global(.rbc-time-header-content) {
  border-color: $default_pastel_grey !important;
}
:global(.rbc-time-header) {
  &:global(.rbc-overflowing) {
    border-color: $default_pastel_grey !important;
  }
}
:global(.rbc-day-bg) {
  & + :global(.rbc-day-bg) {
    border-color: $default_pastel_grey !important;
  }
}

:global(.rbc-label) {
  font-size: 0.9375rem;
}

:global(.rbc-header) {
  font-size: 0.9375rem !important;
}

:global(.custom-control) {
  padding-left: 1.25rem !important;

  :global(.custom-control-label) {
    color: $default_white;
    font-size: 0.9375rem;

    &::after {
      height: 0.9375rem;
      width: 0.9375rem;
    }

    &::before {
      height: 0.9375rem;
      width: 0.9375rem;
    }
  }
}

:global(.btn-list) {
  :global(.btn) {
    font-size: 0.9375rem;

    i {
      font-size: 0.9375rem !important;
    }
  }
}

:global(.card-title) {
  font-size: 0.9375rem !important;
}

:global(.card-options) {
  a {
    i {
      font-size: 1.5625rem !important;
    }
  }
}

:global(.card-body) {
  min-height: 18.75rem;
}
