@import '@Misc/styles/defs/colors.module.scss';

.title {
  box-sizing: border-box;
  flex-grow: 1;
  padding: 0.625rem;
  padding-bottom: 0;
}

.wrapper {
  display: flex;
}

.formInput {
  background-clip: padding-box;
  background-color: $default_white;
  border: 0.0625rem solid rgba(0, 40, 100, 0.12);
  border-radius: 0.1875rem;
  color: $default_trout;
  display: block;
  font-size: 0.585938rem;
  line-height: 1.6;
  padding: 0.234375rem 0.46875rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

span.errorMessage {
  animation: none;
  background: none;
  background-color: none;
  border: none;
  bottom: -0.6875rem;
  color: $default_thunderbird;
  float: right;
  font-size: 0.5rem;
  margin: 0 0 0.625rem 0;
  padding: 0;
  text-align: right;
}

.customSelect {
  position: relative;

  & .select {
    display: none;
  }
}

.formSelect {
  background-clip: padding-box;
  background-color: $default_white;
  border: 0.0625rem solid rgba(0, 40, 100, 0.12);
  border-radius: 0.1875rem;
  color: $default_trout;
  font-size: 0.585938rem;
  height: 1.484375rem;
  line-height: 1.6;
  margin-bottom: 0.625rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  width: 100%;
}

.form {
  display: flex;
  flex: 1;
  flex-basis: 100%;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.card {
  flex-basis: 100%;
  height: 100%;
}
