@import '@Styles/defs/colors.module.scss';

:global(.loader) {
  &.loader {
    border-color: $default_pastel_grey;
    border-top-color: $default_white;

    &::after {
      border-top-color: $default_white;
    }
  }
}
