@font-face {
  font-family: 'NoirPro-Bold';
  src: url('fonts/NoirPro/NoirPro-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'NoirPro';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/NoirPro/NoirPro-Light.otf') format('opentype');
}

@font-face {
  font-family: 'NoirPro';
  font-style: italic;
  font-weight: 300;
  src: url('fonts/NoirPro/NoirPro-LightItalic.otf') format('opentype');
}

@font-face {
  font-family: 'NoirPro';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/NoirPro/NoirPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'NoirPro';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/NoirPro/NoirPro-Italic.otf') format('opentype');
}

@font-face {
  font-family: 'NoirPro';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/NoirPro/NoirPro-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'NoirPro';
  font-style: italic;
  font-weight: 500;
  src: url('fonts/NoirPro/NoirPro-MediumItalic.otf') format('opentype');
}

@font-face {
  font-family: 'NoirPro';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/NoirPro/NoirPro-SemiBold.otf') format('opentype');
}

@font-face {
  font-family: 'NoirPro';
  font-style: italic;
  font-weight: 600;
  src: url('fonts/NoirPro/NoirPro-SemiBoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'NoirPro';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/NoirPro/NoirPro-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'NoirPro';
  font-style: italic;
  font-weight: 700;
  src: url('fonts/NoirPro/NoirPro-BoldItalic.otf') format('opentype');
}

@font-face {
  font-family: 'NoirPro';
  font-style: normal;
  font-weight: 800;
  src: url('fonts/NoirPro/NoirPro-Heavy.otf') format('opentype');
}

@font-face {
  font-family: 'NoirPro';
  font-style: italic;
  font-weight: 800;
  src: url('fonts/NoirPro/NoirPro-HeavyItalic.otf') format('opentype');
}
