.preview {
  margin-top: 1.5rem;

  .label {
    font-size: 0.46875rem;
    font-weight: bold;
    margin-bottom: 0.3125rem;
    text-transform: uppercase;
  }

  .image {
    align-items: center;
    border: 0.0625rem solid rgba(0, 40, 100, 0.12);
    display: flex;
    height: 25rem;
    justify-content: center;
    padding: 0.15625rem;

    img {
      display: block;
      max-height: 100%;
      max-width: 100%;
      padding: 0.625rem;
    }
  }

  .info {
    margin: 0 1.875rem;
    text-align: center;
  }
}

.controls {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.625rem;
}

.input {
  margin-bottom: 1.5rem;
}
