.toast {
  align-items: center;
  bottom: 1.25rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  justify-content: center;
  min-height: 2.5rem;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 9999;
}

.electronToast {
  top: 1.25rem;
  bottom: auto;
}
