.loader {
  display: flex;
  justify-content: center;
  margin-top: 6.25rem;
  position: relative;
  height: 20px;
}

.searchInput {
  margin-bottom: 0.625rem;
}
