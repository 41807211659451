@import '@Misc/styles/defs/colors.module.scss';
@import '@Misc/styles/defs/mixins.module.scss';

.card {
  margin-top: 1.25rem;
  padding: 0.625rem;
}

.select.disabled {
  background: $default_disabledGrey;
  color: $default_grey;
  pointer-events: none;
}

.ratio {
  align-items: center;
  display: flex;

  .input {
    flex-grow: 1;
  }

  .number {
    border: 0.0625rem solid rgba(0, 40, 100, 0.12);
    border-radius: 0.1875rem;
    box-shadow: 0 0.0625rem 0.125rem 0 rgba(0, 0, 0, 0.05);
    margin-left: 0.625rem;
    padding: 0.625rem;
    text-align: right;
    width: 6.25rem;
  }
}

.userData {
  background: $default_wild_sand !important;
  padding: 0.9375rem;
}

.datepicker {
  padding-left: 0.46875rem;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  background: $default_wild_sand;
  border-radius: 0.625rem;
  height: 0.375rem;
  outline: none;
  width: 100%;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: $default_matisse;
    border-radius: 0.375rem;
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
  }

  &::-moz-range-thumb {
    background: $default_matisse;
    border-radius: 0.375rem;
    cursor: pointer;
    height: 2.5rem;
    width: 2.5rem;
  }
}

.loader {
  @include loader();
}

.wrapper {
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.25rem;

    .title {
      display: inline-block;
      font-weight: bold;
    }

    .customButton {
      margin-left: 0.625rem;
    }
  }
}

.info {
  text-align: center;
}

:global(.smallMobile),
:global(.mobile) {
  .wrapper {
    .titleWrapper {
      flex-direction: column;
    }
    .customButton {
      margin: 0 0.625rem 0 0;
    }
    .buttonList {
      display: flex;
      justify-content: space-between;
    }
  }
}

.stepperWrapper {
  .stepHeader {
    color: $default_pastel_grey;
    font-size: 1.125rem;
    margin-bottom: 1.5rem;
  }
  .stepperReservation {
    .steeperGrid {
      position: relative;
      .stepperItem {
        align-items: center;
        background-color: $default_bunker;
        border-radius: 2.5rem;

        cursor: pointer;
        display: flex;
        font-size: 1.5rem;
        font-weight: 300;
        height: 10rem;
        justify-content: center;
        text-align: center;
        user-select: none;
      }

      .bundle {
        background-image: url('~@Images/buddleFull.png');
        background-size: 100% 100%;
        display: none;
        font-size: 0.75rem;
        font-weight: 400;
        height: 2.8125rem;
        left: 24%;
        line-height: 0.9rem;
        padding: 0.6875rem 1.1875rem;
        padding-bottom: 4.375rem;
        position: relative;
        position: absolute;
        top: -5rem;
        width: 19.1875rem;

        &.selected {
          display: block;
        }

        .text {
          padding-top: 0.6875rem;
        }
      }
    }
  }
}

.sticky {
  position: sticky;
  top: 1.25rem;
  padding: 1.5rem;
}

.loader {
  display: flex;
  justify-content: center;
}

.tooltip {
  height: 4.375rem;
}

.selected {
  background-color: $default_crusta;
  border: 0.3125rem solid $default_white;
  font-weight: 400;
}
