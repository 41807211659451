@import '@Misc/styles/defs/mixins.module.scss';

.wrapper {
  color: white;
}

.itemList {
  &:global(.list-group-item) {
    background-color: $default_main !important;
    border-bottom: 0.0625rem solid $default_white;
    color: $default_white;
    font-size: 1.25rem !important;
  }
}

.item {
  background-color: $default_charade !important;
  color: $default_white;
  :global(.card-options) {
    white-space: nowrap;

    a,
    a:hover {
      color: $default_white;
    }
  }

  .color {
    @include color;
  }

  .title {
    font-size: 1.375rem;
    margin: 0;
  }

  .body {
    padding: 0;
  }
}

.spaces {
  color: $default_black;

  :global(.list-group-item) {
    align-items: center;
    display: flex;
    font-size: 0.546875rem;
    margin-bottom: 0;
    padding: 0.9375rem 0.9375rem;

    &:last-child {
      border: none;
    }
  }

  :global(.avatar) {
    margin-right: 0.3125rem;
  }
}

.loader {
  @include loader();
}

.buttonList {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1.25rem;

  :global(.btn-list) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    :global(.btn) {
      &:global(.btn-secondary) {
        align-items: center;
        background-color: transparent;
        border: 0.0625rem solid $default_white;
        border-radius: 1.5rem;
        color: $default_white;
        cursor: pointer;
        display: flex;
        font-size: 1rem;
        font-size: 1rem;
        font-style: normal;
        font-weight: normal;
        justify-content: center;
        line-height: 1.1875rem;
        min-height: 2.5rem;
        padding: 0.6875rem;
        text-align: center;
      }
    }
  }
}
