@import '@Styles/defs/colors.module.scss';
@import '@Styles/defs/props.module.scss';

.title {
  font-size: 1.875rem;
}

.description {
  font-size: 0.625rem;
  padding: 0.625rem 0;
}

.center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.section {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.bigIcon {
  margin-bottom: 2.5rem;
}

.buttonContainer {
  flex: 1;
  justify-content: space-around;
}

.paper {
  padding: 1.5rem;
}

.loader {
  display: flex;
  justify-content: center;
  margin-top: 1.25rem;
}
