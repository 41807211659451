@import '@Styles/defs/props.module.scss';

@mixin center {
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: $default_desktopWidth;
  padding: 0 0.78125rem;
}

@mixin color {
  background: $default_calendarItem;
  border-radius: 0.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  width: 1.5rem;
  margin-top: 1.375rem;
  margin-bottom: 1.375rem;
}

@mixin loader {
  align-items: center;
  display: flex;
  height: 6.25rem;
  justify-content: center;
  width: 100%;
}

@mixin disabledStyles($opacity: 0.6) {
  cursor: initial;
  opacity: $opacity;
  pointer-events: none;
}

@mixin disabledClass($opacity: 0.6) {
  transition: opacity $default_animationTime;

  &.disabled {
    @include disabledStyles($opacity);
  }
}
