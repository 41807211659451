.center {
  display: flex;
  justify-content: center;
}

.loader {
  align-items: center;
  display: flex;
  height: 6.25rem;
  justify-content: center;
  width: 100%;
}
