@import '@Misc/styles/defs/colors.module.scss';
@import '@Styles/defs/props.module.scss';

.scroller {
  padding: 0.3125rem 0.46875rem;

  .title {
    font-size: 0.546875rem;
    text-align: center;
  }

  ul {
    height: 5.625rem;
    list-style: none;
    margin: 0;
    overflow-y: scroll;
    padding: 0;

    li {
      display: flex;
      justify-content: center;
      padding: 0;

      button {
        background: none;
        border: none;
        line-height: 1;
        padding: 0.25rem 0.1875rem;
        transition: $default_transitionTime;

        &:hover {
          background: $default_grey;
          cursor: pointer;
        }
      }
    }
  }
}
