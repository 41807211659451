@import '@Misc/styles/defs/colors.module.scss';

:global(.smallMobile),
:global(.mobile),
:global(.tablet) {
  .wrapper {
    padding: 0;
    box-shadow: none;
    max-height: none;
  }
}

.wrapper {
  background-color: $default_main;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: fit-content;
  padding: 0 0.5rem;
  overflow: scroll;
  max-height: calc(100vh - 192px);
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.withoutRadius {
  border-radius: 0;
  padding: 0;
  box-shadow: none;
}
