@import '@Misc/styles/defs/mixins.module.scss';
@import '@Misc/styles/defs/colors.module.scss';

:global(.tablet),
:global(.mobile),
:global(.smallMobile) {
  .headBar {
    margin-left: 0;
    margin-right: 1.125rem;
  }
  .wrapper {
    max-width: 100% !important;
  }
  .menu {
    width: 0;
  }
}
:global(.bigDesktop) {
  .wrapper {
    max-width: calc(100% - 14.875rem) !important;
  }
}

:global(.MuiGrid-container) {
  &.container {
    flex-wrap: nowrap;
    margin-top: 4rem;
  }
}

.paper {
  margin: 1rem;
  min-height: 18.75rem;
  height: 100%;
}

.title {
  color: $default_white;
  font-size: 1.875rem;
  font-weight: bold;
  margin-left: 3.125rem;
}

.headBar {
  color: $default_white;
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
  margin-left: 3.125rem;
  margin-right: 3.125rem;
  margin-top: 0.9375rem;

  .routes {
    align-items: center;
    display: flex;
    justify-content: center;

    .homeIcon {
      background-image: url('~@Images/menu/home.svg');
      height: 1.5rem;
      margin-right: 0.3125rem;
      width: 1.5rem;
    }
  }
  .userInfo {
    align-items: center;
    display: flex;
    justify-content: center;

    .logout {
      cursor: pointer;
    }

    .partner {
      margin-right: 0.625rem;
      min-width: fit-content;

      :global(.custom-select) {
        padding-top: 0.525rem;
        padding-bottom: 0.525rem;
      }
    }

    .ring {
      background-image: url('~@Images/menu/home.svg');
      background-repeat: no-repeat;
      height: 1.5rem;
      margin-right: 2.5rem;
      width: 1.5rem;
    }
    .settings {
      background-image: url('~@Images/menu/settings.svg');
      background-repeat: no-repeat;
      height: 1.5rem;
      margin-right: 2.5rem;
      width: 1.5rem;
    }
    .avatar {
      background-image: url('~@Images/menu/avatar.svg');
      background-repeat: no-repeat;
      height: 2.25rem;
      margin-right: 1rem;
      width: 2.25rem;
    }
  }
}
